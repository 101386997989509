import { route, string as stringParam } from "react-router-typesafe-routes/dom";
import { useAuthData, useAuthIsLoading, useLogout, useMyId } from "shared/dist/auth-data";
import { useEnv, useOrigin } from "shared/dist/util/env";

import { Capacitor } from "@capacitor/core";
import { ReportTypeParam } from "../screens/user-page/settings/safety";
import { useNavigate } from "react-router-dom";

export const allRoutes = {
  HOME: route("home", {}),
  ROOT: route("", {}),
  me: route("me", {}),
  logout: route("logout", {}),
  login: route("login"),
  PROMO: route("promotion/:partner_id", {
    params: { partner_id: stringParam() },
  }),
  INVITATION_CODE_ENTRY: route("invitation-code", {}),
  ONBOARDING: route("onboarding", {}),
  MEDIA_FROM_TOKEN: route("_mi/:media_upload_id", {}),
  PERSONA_VERIFICATION_RESPONSE: route("i/persona-verification-response", {}),
  REPORT_MEDIA: route("report/:reportType/:media_upload_id", {
    params: { media_upload_id: stringParam(), reportType: ReportTypeParam() },
  }),
  terms: route("terms", {}),
  privacy: route("privacy", {}),
  EVENT_FROM_SLUG: route(
    "e/:slug",
    { params: { slug: stringParam() } },
    {
      COVER_IMAGE: route("cover_image"),
      SPLASH: route("splash"),
      ATTENDEES: route("attendees"),
    }
  ),
  EVENTS: route(
    "events",
    {},
    {
      PUBLIC: route(
        "public",
        {},
        {
          DETAILS: route(":event_slug", {
            params: { event_slug: stringParam() },
          }),
        }
      ),
      LIST: route(
        "list",
        {},
        {
          MY_PAST: route("past"),
          MY_UPCOMING: route("upcoming"),
        }
      ),
      CREATE: route("new", {}),
      DETAILS: route(
        "d/:event_id",
        {
          params: { event_id: stringParam() },
        },
        {
          EDIT: route("edit"),
        }
      ),
    }
  ),
  PROFILE: route(
    "@:slug",
    { params: { slug: stringParam() } },
    {
      PROFILE_IMAGE: route("profile_image"),
      GALLERY: route("gallery"),
      TRAVEL: route("travel"),
      EVENTS: route("events"),
      MEDIA_ITEM: route("m/:media_upload_id", {
        params: { media_upload_id: stringParam() },
      }),
      NOTIFICATIONS: route("notifications", {}),
      ABOUT: route("about"),
      FRIENDS: route(
        "friends",
        {},
        {
          INBOUND_REQUESTS: route("requests"),
          OUTBOUND_REQUESTS: route("my-outgoing-requests"),
          MUTUAL: route("mutual"),
        }
      ),
      INVITES: route("invite"),
      VOUCH: route("vouch/:first_vouch/:from_slug", {
        params: { from_slug: stringParam(), first_vouch: stringParam() },
      }),
      VOUCHES: route("vouches"),
    }
  ),
  DISCOVERY: route(
    "discovery",
    {},
    {
      MY_INCOMING_LIKES: route("my-likes"),
      MATCHES: route("matches"),
      TEST_WAITLIST: route("test-waitlist"),
      TEST_EMPTY: route("test-empty"),
      TEST_NOT_READY: route("test-not-ready"),
      TEST_DUMMY_DATA: route("test-dummy-data"),
      TEST_ERROR: route("test-error"),
    }
  ),
  REQUESTS: route(
    "r",
    {},
    {
      DETAIL: route(":relationship_id", {
        params: { relationship_id: stringParam() },
      }),
    }
  ),
  MESSAGING: route(
    "m",
    {},
    {
      LIST: route(
        "threads",
        {},
        {
          THREAD: route(":thread_id", { params: { thread_id: stringParam() } }),
        }
      ),
    }
  ),
  RELATIONSHIP_INVITE: route("a/i/:invitation_code", {
    params: { invitation_code: stringParam() },
  }),
  SUPPORT: route("support"),
  SETTINGS: route(
    "a/settings",
    {},
    {
      PROFILE: route("profile"),
      PERSONAL: route("personal"),
      DATING: route("dating"),
      PRIVACY: route("privacy"),
      ACCOUNT: route("account-center"),
      BLOCKED: route("hidden"),
      HIDDEN: route("blockedter"),
      // Old settings Tabs: TODO delete
      DETAILS: route("details"),
      NOTIFICATIONS: route("notifications"),
      RELATIONSHIPS: route("relationships"),
    }
  ),
  DEV_UTILS: route(
    "d",
    {},
    {
      COLORS: route("colors"),
      INFO: route("info"),
      UI_TEST_PAGES: route(
        "ui-test-pages",
        {},
        {
          TEST_BIO_ACTIONS: route("test-bio-actions"),
        }
      ),

      // NOTIFICATION_TEST: route("notifications-testing"),
    }
  ),
};

export function useMustNotBeLoggedIn() {
  const ad = useMyId();
  console.log("🚀 ~ file: routes.ts ~ line 28 ~ useMustNotBeLoggedIn ~ ad", ad);
  const navigate = useNavigate();
  const loading = useAuthIsLoading();
  console.log("🚀 ~ file: routes.ts ~ line 31 ~ useMustNotBeLoggedIn ~ loading", loading);
  if (loading) {
    return;
  }
  if (ad) {
    setTimeout(() => navigate(allRoutes.me.buildPath({})), 100);
  }
}

export function useMustBeLoggedIn() {
  const ad = useMyId();
  const navigate = useNavigate();
  const loading = useAuthIsLoading();
  if (loading) {
    return;
  }
  if (!ad) {
    navigate(allRoutes.HOME.buildPath({}));
    return null;
  }
  return ad;
}

export function useLogoutAndRedirect() {
  const navigate = useNavigate();
  const logout = useLogout();
  return () => {
    logout();
    navigate(allRoutes.ROOT.buildPath({}));
  };
}

export type Size = "1280" | "128" | "256" | "avatar" | "md" | "lg" | "full" | "thumb" | "dummy";
export const useMkImgSrcSet = () => {
  const native = Capacitor.isNativePlatform();
  const token = useAuthData()?.token;
  const tokenStr = native && token ? `&auth_token=${token}` : "";
  const origin = useOrigin().replace(/\/$/, "");
  return (mediaId: string) => {
    const path = allRoutes.MEDIA_FROM_TOKEN.buildPath({
      media_upload_id: mediaId,
    });
    return [128, 1280]
      .map((size) => `${origin}${path}?size=${size}${tokenStr} ${size}w`)
      .join(", ");
  };
};

export const useMkImgUrl = () => {
  const native = Capacitor.isNativePlatform();
  const token = useAuthData()?.token;
  const tokenStr = native && token ? `&auth_token=${token}` : "";
  const origin = useOrigin().replace(/\/$/, "");
  return (mediaId: string, size?: Size) =>
    `${origin}${allRoutes.MEDIA_FROM_TOKEN.buildPath({ media_upload_id: mediaId })}?size=${
      size ?? "md"
    }${tokenStr}`;
};
