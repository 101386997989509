import React from "react";

import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useEventDetailsBySlug553Query } from "shared/dist/__generated__/components";
import { allRoutes } from "../../../../util/routes";

import simmrLogo from "../../../../assets/simmr-logo-red.png";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { useEnv } from "shared/dist/util/env";
import { DateTime } from "luxon";
import { formatDate } from "../../../../util/datetime-formatting";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Text } from "../../../../components/atoms/Text";
import clsx from "clsx";
import Linkify from "linkify-react";

const PublicEventsDetail: React.FC = () => {
  const { event_slug } = useTypedParams(allRoutes.EVENTS.PUBLIC.DETAILS);
  const [expanded, setExpanded] = React.useState(false);

  if (typeof event_slug === "undefined") {
    return <ErrorComp caption="Invalid event slug" />;
  }
  const { data, loading } = useEventDetailsBySlug553Query({ variables: { url_slug: event_slug } });
  const event = data?.event_public_summaries[0];

  if (!event) return <ErrorComp caption={`Event ${event_slug} not found`} />;

  if (loading) return <SpinnerCentered />;

  const baseUri = useEnv("REACT_APP_BASE_URI");
  const eventImgUrl =
    baseUri.replace(/\/$/, "") +
    allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({
      slug: event.url_slug!,
    });

  return (
    <div className="min-h-full flex flex-col gap-4 px-6 w-screen bg-[#2A2C38] text-white">
      <div className="flex items-center justify-center gap-2 py-4 mx-8">
        <img className="w-32" src={simmrLogo} alt="Simmr logo" />
        <h1 className="text-3xl tracking-widest font-thin">EVENTS</h1>
      </div>
      <h3 className="px-14 text-md text-center">
        Want to connect with open-minded ENM people in the wild? 😈
      </h3>
      <div className="flex flex-col gap-4 lg:flex-row">
        <img className="w-full max-h-64" src={eventImgUrl} />
        <div className="flex flex-col px-6">
          <h2 className="font-quicksand text-center text-2xl tracking-wider">
            Link up with the Simmr community at
          </h2>
          <h2 className="font-quicksand text-center text-2xl font-semibold tracking-wider">
            {event.name}
          </h2>
        </div>
      </div>
      <div className="flex flex-col gap-2 lg:bg-[#373A49] text-xl">
        <p>&#128197; Date: {formatDate(event.start!)}</p>
        <p>&#128205; Location: Shared with Simmr Verified</p>
        <div className="flex items-center gap-2">
          {event.event_attendees.slice(0, 3).map(({ user_summary }, i) => (
            <Avatar
              key={i}
              slug={user_summary?.slug!}
              className="border-2 border-white first:ml-0 -ml-4 inline-block"
              tailwindSize="8"
            />
          ))}
          <p className="text-lg text-[#FE635F]">
            {event.event_attendees.length}+ members attending
          </p>
        </div>
        <div className="relative">
          <div className={clsx("overflow-hidden", expanded ? "line-clamp-none" : "line-clamp-3")}>
            {event.description?.split("\n").map((line, i) => (
              <Linkify
                options={{
                  target: "_blank",
                  className: "whitespace-pre-line link font-semibold",
                }}
                as="p"
                key={i}
              >
                {line}
                {"\u00A0"}
              </Linkify>
            ))}
          </div>
          <a
            className="cursor-pointer inline ml-auto text-bold text-[#FE635F]"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Show Less" : "Show More"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PublicEventsDetail;
