import { DateTime } from "luxon";
// Helper function to add ordinal suffix to a day
const getOrdinalSuffix = (day: number) => {
  const j = day % 10;
  const k = day % 100;
  if (j === 1 && k !== 11) {
    return `${day}st`;
  } else if (j === 2 && k !== 12) {
    return `${day}nd`;
  } else if (j === 3 && k !== 13) {
    return `${day}rd`;
  } else {
    return `${day}th`;
  }
};

export const formatDate = (isoString: string): string => {
  const dt = DateTime.fromISO(isoString);

  if (!dt.isValid) {
    throw new Error("Invalid ISO string");
  }

  return `${dt.toFormat("cccc MMM ")}${getOrdinalSuffix(dt.day)} @ ${dt.toFormat("h a")}`;
};
